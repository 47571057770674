.maincontainer {
  height: 85vh;
  width: 80vw;
  background: #ececec;
  border-radius: 10px;
  overflow-y: auto;
  padding: 2vw;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading {
  font-size: 1.6vw;
  color: #535962;
}

.topnavigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3vw;
}

.navitemholder {
  width: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navitemholderactive {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  border-bottom: 3px solid #535962;
  width: 5vw;
}

.navitemholder:hover {
  cursor: pointer;
}

.navitem {
  font-size: 1vw;
  color: #535962;
}

.navitemactive {
  font-size: 1vw;
  color: #535962;
}

.rightselection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2vw;
}

.dayselect {
  background: #226bf6;
  width: 10vw;
  padding: 0.6vw;
  color: white;
  border-radius: 10px;
  font-size: 1vw;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url("https://www.svgrepo.com/show/80156/down-arrow.svg");
  background-repeat: no-repeat;
  background-size: 1vw 1vw;
  background-position: calc(100% - 16px);
}

.closeimage {
  height: 1.8vw;
  width: 1.8vw;
}

.closeimage:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.middlebar {
  padding: 4vh 0px;
}

.graphcontainer {
  width: 100%;
  height: 50%;
  overflow: hidden;
}

.topcountplayholder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3vw;
}

.topcountholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3vw;
}

.dataholder {
  width: 24vw;
  min-height: 10vh;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2vw;
  background-image: url("../../../../../Assets/images/countbackground.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.dataplayholder {
  width: 24vw;
  min-height: 10vh;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2vw;
  background-image: url("../../../../../Assets/images/countbackground.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.graphholder {
  flex: 1;
  margin-top: 20px;
  background: white;
  padding: 3vw;
}

.data {
  font-size: 1vw;
  color: white;
  font-weight: 400;
}

.bolddata {
  font-size: 1.4vw;
  color: white;
  font-weight: 600;
}

.imageplaceholder {
  width: 1.4vw;
  height: 1vw;
}

.carouselholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventimage {
  width: 50%;
  height: auto;
  object-fit: cover;
}

.splitgraph {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: row;
  background: white;
  height: 50vh;
  margin-top: 3vh;
  padding: 5vw;
}

.leftside {
  flex: 1;
}

.leftlabel {
  font-size: 1vw;
  font-weight: 300;
}

.youthvalue {
  font-size: 1.5vw;
  color: #7ed957;
  font-weight: 700;
}

.adultvalue {
  font-size: 1.5vw;
  color: #00b980;
  font-weight: 700;
}

.teenvalue {
  font-size: 1.5vw;
  color: #009299;
  font-weight: 700;
}

.middleagevalue {
  font-size: 1.5vw;
  color: #006995;
  font-weight: 700;
}

.kidvalue {
  font-size: 1.5vw;
  color: rgba(153, 102, 255, 0.2);
  font-weight: 700;
}

.emojiholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10vw;
  padding: 3vh 0vh;
}

.emojiicons {
  height: 5vw;
  width: 5vw;
}

.individualholders {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.happygreen {
  color: #2ba65a;
  font-size: 2vw;
}

.normalyellow {
  color: #d6a600;
  font-size: 2vw;
}

.sadred {
  color: #ee4143;
  font-size: 2vw;
}

///////////////////////////////////////////////////////////
//////////////Modal Styles//////////////////////////////////

.modalholder {
  height: 80vh;
  width: 50vw;
  padding: 2vw;
  border-radius: 10px;
  background: white;
  overflow-y: scroll;
}

.modaltopbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0px;
}

.closeicon {
  width: 1.5vw;
  height: 1.5vw;
}

.image {
  width: 100%;
  height: auto;
}

.closeicon:hover {
  cursor: pointer;
  transform: scale(1.04);
}
