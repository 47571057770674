@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-weight: 400;
}

:root {
  --purple-primary: #5e72e4;
  --blue-primary: #226bf6;
}

$sidebar-bg-color: #fff;
$submenu-bg-color: #fff;
$submenu-bg-color-collapsed: #fff;
$sidebar-color: #fff;
$highlight-color: #226bf6;
$icon-bg-color: #000 !default;
$icon-size: 20px;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #fff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;
}

.sidebar-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .sidebar-btn {
    transition: width 0.3s;
    width: 100px;
    padding: 1px 15px;
    border-radius: 40px;
    background: #fff;
    color: #000;
    text-decoration: none;
    margin: 0 auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
    span {
      margin-left: 5px;
      font-size: 13px;
    }
  }
}

.pro-sidebar-content {
  width: 100%;
}

.pro-sidebar {
  min-width: 16vw;
  height: 82vh;
  margin: 3vh 0vh 3vh 3vh;
  color: #000;
  overflow-y: hidden;
}

.collapsed {
  .sidebar-btn {
    display: inline-block;
    line-height: 40px;
    padding: 0;
  }
}

main {
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  header {
    margin-bottom: 40px;
    h1 {
      display: flex;
      align-items: center;
      transform: translateX(-20px);
    }
  }

  footer {
    margin-top: auto;
    text-align: center;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.social-bagdes {
  margin-top: 10px;
  img {
    margin: 5px;
  }
}

.block {
  display: flex;
  margin-bottom: 24px;
  font-size: 14px;
  > span {
    margin-left: 10px;
  }
}

//Style of react responsive carousel /////////////////////////////

.carousel-root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.thumbs-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .slide .legend {
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 40%;
  height: fit-content;
  border-radius: 10px;
  background: #2f2d30;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: left;
  opacity: 1;
}

.legend:hover {
  transform: none;
}

.carousel.carousel-slider {
  overflow: inherit;
}

.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
  background-color: transparent;
  right: -146px;
}

.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
  background-color: transparent;
  left: -146px;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}

.carousel .control-next.control-arrow:before {
  content: "";
  border: solid #0135ad;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 14px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
  content: "";
  border: solid #0135ad;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 14px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
/////////////////////////////////////////////////////////

@media (max-width: $breakpoint-md) {
  .btn-toggle {
    display: flex;
  }

  &.rtl {
    .btn-toggle {
      margin-left: auto;
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none;
}

.semicircle-percent-value {
  font-size: 0.9vw;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  font-size: 0.8vw;
  color: #000;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu.open
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  fill: #000000;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  border-color: #000000;
}

// Media Query for mobile devices
@media (max-width: 480px) {
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1500px) {
}

/* Media Query for Large screens */
@media (min-width: 1500px) {
}
