// Main sidenav container style
.sidemain {
  background: #fff;
  width: 18vw;
  min-height: 82vh;
  padding: 0px 0px 0px 2vh;
  border-radius: 10px;
  overflow-y: hidden;
}

.logoimage {
  height: auto;
  width: 80%;
}

.menuheading {
  font-size: 1vw;
  font-weight: 300px;
  color: #000;
  background: white;
  border-radius: 5px;
  margin: 0.5vw 0px;
}

.menuheadingactive {
  font-size: 1vw;
  font-weight: 400px;
  color: #5e72e4;
  border-radius: 5px;
}

.submenu {
  font-size: 0.4vw;
  color: #fff;
}

.submenucontaineractive {
  list-style: none;
  background: #ececec;
  margin-left: -10px;
}

.submenucontainer {
  list-style: none;
  color: white;
  background: #fff;
  margin-left: -0.5vw;
  border: none;
}

// Side nav body styles
.menucontent {
  width: 100%;
  padding-top: 20px;
  min-height: 40vh;
  border: none;
  overflow-y: auto;
}

.icon {
  height: 1.4vw;
  width: 1.4vw;
}

// Side nav footer styles
.sidenavfooter {
  width: 100%;
  min-height: 34vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
}

@media screen and (min-width: 200px) {
}

@media screen and (min-width: 300px) {
}

@media screen and (min-width: 400px) {
}

@media screen and (min-width: 500px) {
}

@media screen and (min-width: 600px) {
}

@media screen and (min-width: 700px) {
}

@media screen and (min-width: 800px) {
}

@media screen and (min-width: 900px) {
}

@media screen and (min-width: 1000px) {
}

@media (min-width: 200px) and (max-width: 1000px) {
  .sidemain {
    display: none;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1000px) and (max-width: 1500px) {
}

/* Media Query for Large screens */
@media (min-width: 1500px) {
}
