.maincontainer {
  box-sizing: border-box;
  background-color: #f3f3f3;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.errormessage {
  color: #ff0000;
  margin-top: -10px;
  font-size: 12px;
}

.correctmessage {
  color: green;
  margin-top: -10px;
  font-size: 12px;
}

.leftside {
  width: 50vw;
  height: 70vh;
  padding: 40px;
  border-right: 1px solid #2f2d30;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.logoimage {
  height: auto;
  width: 300px;
}

.signupicon {
  height: 40vh;
  width: auto;
}

.rightside {
  width: 50vw;
  height: 70vh;
  padding: 40px;
  border-left: 1px solid #2f2d30;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.heading {
  font-size: 48px;
  font-weight: 200;
}

.formholder {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.fieldimage {
  height: 20px;
  width: auto;
}

.inputholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  padding: 30px;
  height: 30px;
  width: 35vw;
  border: 1px solid #2f2d30;
  border-radius: 10px;
}

textarea:focus,
select:focus,
input:focus {
  outline: none;
}

.inputfield {
  background: transparent;
  border: none;
  font-size: 21px;
  width: 100%;
}

.selectinputfield {
  background: transparent;
  border: none;
  font-size: 21px;
  width: 25vw;
  color: #757575;
}

.button {
  font-size: 18px;
  padding: 15px 80px;
  margin-top: 20px;
  background: #226bf6;
  border: none;
  border-radius: 10px;
  color: white;
}

.button:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.inputholder:hover {
  transform: scale(1.03);
}

option:first {
  color: #999;
}

.nouserholder {
  padding: 10px 0px;
}

.nouser {
  font-size: 0.8vw;
  font-weight: 300;
}

.signuplink {
  color: #226bf6;
  font-size: 0.8vw;
}

.signuplink:hover,
.refreshicon {
  cursor: pointer;
}

// Media Query for mobile devices
@media (max-width: 480px) {
  .maincontainer {
    flex-direction: column;
  }

  .leftside {
    border: none;
    height: 20vh;
  }

  .heading {
    font-size: 40px;
    font-weight: 200;
  }

  .signupicon {
    display: none;
  }

  .rightside {
    border: none;
    height: 80vh;
    justify-content: flex-start;
  }

  .inputholder {
    width: 70vw;
  }

  .inputfield {
    font-size: 20px;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .maincontainer {
    flex-direction: column;
  }

  .leftside {
    border: none;
  }

  .signupicon {
    display: none;
  }

  .rightside {
    border: none;
  }

  .inputholder {
    width: 70vw;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
}
