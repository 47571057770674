.mainpopup {
  height: fit-content;
  width: 50vw;
  background-color: white;
  padding: 50px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 15px;
}

.heading {
  font-size: 22px;
  font-weight: 300;
  text-align: center;
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}

.primarybutton {
  width: 250px;
  padding: 10px 20px;
  border: 1px solid #226bf6;
  border-radius: 5px;
  background: transparent;
  color: #226bf6;
  font-size: 12px;
}

.secondarybutton {
  width: 250px;
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  background: transparent;
  color: black;
  font-size: 12px;
}

.bottombutton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.primarybutton:hover,
.secondarybutton:hover {
  transform: scale(1.04);
  cursor: pointer;
}
