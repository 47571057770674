.mainmodal {
  height: fit-content;
  width: 70vw;
  background-color: white;
  padding: 50px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.heading {
  font-size: 24px;
  font-weight: 300;
}

.imageforroi {
  width: 50vw;
  height: auto;
}

.continuebutton {
  background: #226bf6;
  color: white;
  font-size: 14px;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
}

.continuebutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.continuebuttondisabled {
  background: grey;
  color: white;
  font-size: 14px;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
}

.continuebuttondisabled:hover {
  cursor: not-allowed;
}

.inputfield {
  background: transparent;
  border: 1px solid #2f2d30;
  border-radius: 10px;
  font-size: 21px;
  padding: 0px 40px 5px 40px;
}

.errormessage {
  color: #ff0000;
  font-size: 12px;
}
