.mainmodal {
  display: flex;
  z-index: 300;
  position: absolute;
  left: 23vw;
  top: 11vh;
  background: #535962;
  padding: 20px;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  border-top-left-radius: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.profileimage {
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding-bottom: 20px;
}

.username {
  color: white;
  font-size: 18px;
}

.useremail {
  color: white;
  font-size: 12px;
}

.editbutton {
  background: transparent;
  border: 1px solid white;
  border-radius: 5px;
  width: 100px;
  color: white;
}

.iconholder {
  width: 30px;
}

.menuholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 5px 20px;
}

.menuheading {
  font-size: 14px;
  color: white;
}

.menuheading:hover {
  cursor: pointer;
}
