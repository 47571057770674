.maincontainer {
  box-sizing: border-box;
  background-color: white;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px;
  height: 20vh;
  background: var(--blue-primary);
}

.leftheader {
  width: 50vw;
}

.rightheader {
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.logoimage {
  width: 16vw;
  height: auto;
}

.username {
  color: white;
  font-size: 20px;
  font-weight: 400;
}

.settingsicon {
  height: 30px;
  width: auto;
}

.settingsicon:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.bodylayout {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 80vh;
}

.leftnavigation {
  width: 25vw;
  height: 80vh;
  background-color: var(--blue-primary);
  padding: 0px;
  text-align: center;
}

.rightfeatures {
  height: 85vh;
  width: 75vw;
  background: white;
  border-radius: 5%;
  margin-top: -5vh;
  padding: 40px 50px;
  overflow-y: scroll;
}

.navigationheading {
  font-size: 24px;
  padding-bottom: 40px;
  color: white;
  font-weight: 300;
}

.navigationholder {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 3vw;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left: 3vw;
  padding: 5px 0px;
  width: 90%;
  height: fit-content;
}

.navigationholderactive {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 3vw;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 0px 10px;
  background: white;
  margin-left: 3vw;
  padding: 5px 0px;
  width: 90%;
  height: fit-content;
}

.navigationholder:hover {
  cursor: pointer;
}

.navtitle {
  font-size: 18px;
  color: white;
  font-weight: 300;
  padding: 10px 0px 10px 30px;
}

.navtitle:hover,
.navtitleactive:hover {
  cursor: pointer;
}

.navtitleactive {
  font-size: 18px;
  color: var(--blue-primary);
  font-weight: 300;
  padding: 10px 0px 10px 30px;
}

.headingright {
  font-size: 24px;
  font-weight: 300;
}

.featuremainholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px 30px;
}

.lefticons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
}

.rightaddbutton {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.iconholder {
  width: 30px;
}

.featureicon {
  height: 15px;
  width: 15px;
  flex: 1;
}

.featureiconholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 30px;
}

.featureheading {
  font-size: 16px;
  font-weight: 300;
  flex: 1;
}

.infoicon {
  height: 15px;
  width: auto;
}

.featurecardholder {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.addbutton {
  font-size: 12px;
  font-weight: 300;
  padding: 5px 10px;
  width: 100px;
  color: var(--blue-primary);
  border: 1px solid var(--blue-primary);
  border-radius: 10px;
  background-color: transparent;
}

.addbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}
