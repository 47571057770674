.mainmodal {
  height: fit-content;
  width: 40vw;
  background-color: white;
  padding: 50px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.heading {
  font-size: 24px;
  font-weight: 300;
}

.formholder {
  width: 100%;
}

.namebox {
  font-size: 16px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 15px;
  font-weight: 300;
  width: 100%;
}

.timeselection {
  padding-top: 20px;
  text-align: start;
}

.inputlabel {
  font-size: 16px;
  font-weight: 300;
}

.timeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.lefttimeholder {
  width: 45%;
}

.righttimeholder {
  width: 45%;
}

.selecttime {
  width: 100%;
  border: 1px solid black;
  border-radius: 10px;
  padding: 15px;
  font-weight: 300;
}

.repeatselection {
  padding-top: 20px;
  text-align: start;
}

.repeatbuttons {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.weekbutton {
  background: #2f2d30;
  color: white;
  border-radius: 5px;
  border: none;
  width: 40px;
  height: 40px;
}

.weekbuttonactive {
  background: #226bf6;
  color: white;
  border-radius: 5px;
  border: none;
  width: 40px;
  height: 40px;
}

.weekbutton:hover,
.weekbuttonactive:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.endsonholder {
  padding-top: 20px;
  text-align: left;
}

.endsholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.radioholder {
  display: flex;
  gap: 10px;
  align-items: center;
}

.endsondate {
  width: 45%;
  border: 1px solid black;
  border-radius: 10px;
  padding: 15px;
  font-weight: 300;
  margin-top: 10px;
}

.continuebutton {
  background: #226bf6;
  color: white;
  font-size: 14px;
  padding: 10px 30px;
  border: none;
  width: 150px;
  border-radius: 5px;
}

.continuebutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.dummyholder {
  width: 45%;
  height: 55px;
}

.errormessage {
  color: #ff0000;
  font-size: 12px;
}
