.maincontainer {
  width: 100vw;
  height: 100vh;
  background: #ececec;
  padding: 10vh;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 30px;
}

.logoimage {
  width: 40vw;
  height: auto;
}

.biggerimage {
  width: 80vw;
  height: auto;
}

.graphtext {
  font-size: 5vw;
  font-weight: 500;
}

.textbelowoops {
  font-size: 3vw;
  font-weight: 400;
  text-align: center;
}
