.mainholder {
  height: 88vh;
  width: 82vw;
  overflow-y: auto;
  background: #5e72e4;
  padding: 30px 50px;
}

.videoholder {
  padding-top: 20px;
}

.camselectdate {
  background: #ffffff;
  width: 14vw;
  padding: 0.6vw;
  border: none;
  color: black;
  border-radius: 10px;
  font-size: 1vw;
}

.camselect {
  background: #ffffff;
  width: 12vw;
  padding: 0.6vw;
  color: black;
  border-radius: 10px;
  font-size: 1vw;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url("https://www.svgrepo.com/show/80156/down-arrow.svg");
  background-repeat: no-repeat;
  background-size: 1vw 1vw;
  background-position: calc(100% - 16px);
}

.camselect:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.selectcameras {
  height: 300px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectholder {
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.innervideoholder {
  width: 100%;
}

.carouselholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}
