.mainpopup {
  height: fit-content;
  width: 40vw;
  background-color: white;
  padding: 50px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 10px;
}

.iconholder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.heading {
  font-size: 24px;
  font-weight: 300;
}

.templatesholder {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 5px;
  padding: 10px 0px;
  width: 100%;
  height: 40vh;
  overflow-y: scroll;
}

.createnewdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  border: 3px dotted #226bf6;
  border-radius: 10px;
  height: 20vh;
  width: 10vw;
}

.template {
  padding: 10px;
  height: 20vh;
  width: 10vw;
  background: #ececec;
  border-radius: 10px;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.templatename {
  font-size: 12px;
  font-weight: 300;
}

.dataholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 5px 0px;
}

.datalabel {
  font-size: 12px;
  font-weight: bold;
}

.datavalue {
  font-size: 12px;
  font-weight: 300;
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}

.button {
  background: #226bf6;
  color: white;
  font-size: 14px;
  padding: 10px 30px;
  border: none;
  width: 200px;
  border-radius: 5px;
}

.buttondisabled {
  background: grey;
  color: white;
  font-size: 14px;
  padding: 10px 30px;
  border: none;
  width: 200px;
  border-radius: 5px;
}

.buttondisabled:hover {
  cursor: not-allowed;
}

.closeicon:hover,
.button:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.createnewdiv:hover {
  cursor: pointer;
}

.days {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px 0px;
}

.weekbutton {
  background: #2f2d30;
  color: white;
  border-radius: 5px;
  border: none;
  width: 20px;
  height: 20px;
}

.weekbuttonactive {
  background: #226bf6;
  color: white;
  border-radius: 5px;
  border: none;
  width: 20px;
  height: 20px;
}

.weekbutton:hover,
.weekbuttonactive:hover {
  cursor: pointer;
  transform: scale(1.04);
}
