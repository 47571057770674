.mainpopup {
  height: fit-content;
  width: 50vw;
  background-color: white;
  padding: 50px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 15px;
}

.iconholder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.heading {
  font-size: 24px;
  font-weight: 300;
}

.featuredescription,
.syncdescription {
  font-size: 16px;
  font-weight: 300;
}

.featureimage {
  width: 100%;
  height: auto;
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}

.button {
  background: #226bf6;
  color: white;
  font-size: 14px;
  padding: 10px 30px;
  border: none;
  width: 150px;
  border-radius: 5px;
}

.closeicon:hover,
.button:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.syncimageholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.syncimage {
  width: 60%;
  height: auto;
}
