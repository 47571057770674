.modalcontainer {
  background: white;
  width: 40vw;
  height: fit-content;
  text-align: center;
  padding: 20px;
  border-radius: 5%;
}

.closeholder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.closeicon {
  height: 20px;
  width: auto;
}

.image {
  height: 25vh;
  width: auto;
}

.headingbelow {
  font-size: 32px;
  font-weight: 400;
}

.phrase {
  padding-top: 20px;
  font-size: 18px;
  text-align: center;
  font-weight: 300;
}

.buttoncontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.downloadicon {
  width: auto;
  height: 20px;
}

.downloadbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: transparent;
  border: 1px solid #226bf6;
  font-size: 14px;
  border-radius: 5px;
  color: #226bf6;
  padding: 5px 30px;
}

.closeicon:hover,
.downloadbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}
