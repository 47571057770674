.maincontainer {
  box-sizing: border-box;
  background-color: white;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px;
  height: 20vh;
  background: #226bf6;
}

.leftheader {
  width: 50vw;
}

.rightheader {
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.logoimage {
  width: 16vw;
  height: auto;
}

.username {
  color: white;
  font-size: 20px;
  font-weight: 400;
}

.settingsicon {
  height: 30px;
  width: auto;
}

.settingsicon:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.belowbody {
  height: 85vh;
  background: white;
  border-radius: 5%;
  margin-top: -5vh;
  padding: 40px 80px 40px 80px;
}

.heading {
  text-align: left;
  font-size: 26px;
  font-weight: 300;
}

.cameracardsholder {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.cameracard {
  padding: 20px;
  width: 20vw;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  height: fit-content;
  background-color: #f3f3f3;
}

.cameracard:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.image {
  width: 100%;
  height: 15vh;
  border-radius: 10px;
}

.cameradetails {
  padding-top: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.cameraname {
  font-size: 20px;
  font-weight: 400;
}

.camerabrand {
  font-size: 16px;
  font-weight: 300;
}

.rightside {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
}

.camerastatus {
  color: green;
}
