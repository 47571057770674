.mainbigcontainer {
  width: 100%/3;
  min-height: 22vh;
  background: white;
  padding: 20px;
  border: none;
  border-radius: 10px;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
}

.underprogressholder {
  text-align: center;
}

.undertraininggif {
  width: 5vw;
  height: 5vw;
}

.underprogress {
  font-size: 1vw;
}

.leftbigholder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.topinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.middleholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 10vh;
}

.leftside {
  flex: 1;
  border-right: 1px solid #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.rightside {
  flex: 1;
  border-left: 1px solid #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.featurevalue {
  font-size: 0.8vw;
}

.featuredatablue {
  font-size: 2vw;
  color: #226bf6;
}

.featuredatagreen {
  font-size: 2vw;
  color: #2ba65a;
}

.featuredatayellow {
  font-size: 2vw;
  color: #fbcd57;
}

.featurename {
  font-size: 1.2vw;
  color: #535962;
}

.featurescoregreen {
  color: #2ba65a;
  font-size: 42px;
  padding-bottom: 5px;
}

.featurescoreyellow {
  color: #fbcd57;
  font-size: 42px;
  padding-bottom: 5px;
}

.infoicon {
  height: 0.9vw;
  width: 0.9vw;
}

.emojidiv {
  height: 20px;
  width: 20px;
}

.rightholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.iconholderred {
  background: #f9bcbd;
  padding: 1vh;
  border: none;
  border-radius: 10px;
}

.featuredetails {
  font-size: 0.9vw;
  font-weight: 300;
  position: relative;
  display: inline-block;
}

.featuredetails:hover {
  cursor: default;
}

.featuredetails .tooltiptext {
  visibility: hidden;
  width: 13vw;
  background-color: black;
  color: #fff;
  font-size: 0.7vw;
  text-align: center;
  border-radius: 6px;
  padding: 0.5vw;

  /* Position the featuredetails */
  position: absolute;
  z-index: 1;
}

.featuredetails:hover .tooltiptext {
  visibility: visible;
  cursor: pointer;
}

.graphholder {
  width: 100px;
  height: 100px;
}

.learnmore {
  font-size: 0.6vw;
  font-weight: 300;
  padding: 0.4vw 2vw;
  border: 1px solid black;
  border-radius: 10px;
  background: transparent;
}

.learnmore:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.rightbigholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.textbelowprogress {
  color: #949494;
  font-weight: 300;
  font-size: 12px;
}

.mainaddcontainer {
  width: 100%/3;
  min-height: 22vh;
  background: transparent;
  padding: 20px;
  border: 1px dashed #fff;
  border-radius: 10px;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.addfeaturetext {
  font-size: 1vw;
  color: white;
}

.datavalueblue {
  font-size: 1.2vw;
  color: #226bf6;
  font-weight: 500;
}

.datavaluered {
  font-size: 1.2vw;
  color: #ee4143;
  font-weight: 500;
}

.datavaluegreen {
  font-size: 1.2vw;
  color: #2ba65a;
  font-weight: 500;
}

.datavalueyellow {
  font-size: 1.2vw;
  color: #fbcd57;
  font-weight: 500;
}

.eventsholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;
}

.eventsholderplay {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5vw;
}

.leftimageholder {
  flex: 1.2;
  height: 100%;
  position: relative;
}

.iconholder {
  border: none;
  border-radius: 10px;
}

.icon {
  height: 3.5vw;
  width: 3.5vw;
}

.featureimage {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.openicon {
  position: absolute;
  width: 15%;
  top: 70%;
  left: 5%;
}

.rightcardholder {
  flex: 1;
  padding: 10px;
}

.middlecardholder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 10px;
}

.semicircleprogressholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9vh;
}

.maleiconholder,
.femaleiconholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.detailicon {
  font-size: 1.2vw;
}

.gendericonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5vw;
  height: 10vh;
  padding-bottom: 1.5vw;
}

// Media Query for mobile devices
@media (max-width: 480px) {
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1500px) {
  .maincontainer {
    width: 24vw;
    min-height: 12vh;
    padding: 10px;
  }

  .featurescoregreen {
    font-size: 36px;
    padding-bottom: 5px;
  }

  .featurescoreyellow {
    font-size: 36px;
    padding-bottom: 5px;
  }

  .graphholder {
    width: 80px;
    height: 80px;
  }

  .infoicon {
    width: 15px;
    height: 15px;
  }
}

/* Media Query for Large screens */
@media (min-width: 1500px) and (max-width: 1700px) {
  .maincontainer {
    width: 24vw;
    min-height: 12vh;
    padding: 10px;
  }

  .featurescoregreen {
    font-size: 36px;
    padding-bottom: 5px;
  }

  .featurescoreyellow {
    font-size: 36px;
    padding-bottom: 5px;

    .graphholder {
      width: 80px;
      height: 80px;
    }
  }
}

@media (min-width: 1700px) {
}

// for 125% zoom
@media (-webkit-min-device-pixel-ratio: 1.25) {
}

// for 150% zoom
@media (-webkit-min-device-pixel-ratio: 1.5) {
}
