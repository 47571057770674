.maincontainer {
  box-sizing: border-box;
  background-color: white;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px;
  height: 20vh;
  background: #226bf6;
}

.leftheader {
  width: 50vw;
}

.rightheader {
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.logoimage {
  width: 16vw;
  height: auto;
}

.downloadicon {
  width: auto;
  height: 25px;
}

.downloadbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: transparent;
  border: 1px solid white;
  font-size: 18px;
  border-radius: 3px;
  color: white;
  padding: 5px 30px;
}

.settingsicon {
  height: 30px;
  width: auto;
}

.downloadbutton:hover,
.settingsicon:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.belowbody {
  height: 85vh;
  background: white;
  border-radius: 5%;
  margin-top: -5vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.onboardingicon {
  height: 45vh;
  width: auto;
}

.headingbelow {
  font-size: 32px;
  font-weight: 400;
}

.phrase {
  padding-top: 20px;
  font-size: 18px;
  text-align: center;
  font-weight: 300;
}

.steptext {
  padding-top: 40px;
  font-size: 18px;
  text-align: center;
  font-weight: 300;
}

.progressholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 15px;
  padding-top: 20px;
}

.first {
  width: 80px;
  height: 10px;
  background-color: #226bf6;
  border-radius: 10%;
}

.second {
  width: 80px;
  height: 10px;
  background-color: #f3f3f3;
  border-radius: 10%;
}

.third {
  width: 80px;
  height: 10px;
  background-color: #f3f3f3;
  border-radius: 10%;
}

.refreshicon:hover {
  cursor: pointer;
}

// Media Query for mobile devices
@media (max-width: 480px) {
  .logoimage {
    width: 16vw;
    height: auto;
  }

  .downloadbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: transparent;
    border: 1px solid white;
    font-size: 12px;
    border-radius: 3px;
    color: white;
    padding: 5px 20px;
  }

  .phrase {
    padding-top: 20px;
    font-size: 12px;
    text-align: center;
    font-weight: 300;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .logoimage {
    width: 16vw;
    height: auto;
  }

  .downloadbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: transparent;
    border: 1px solid white;
    font-size: 12px;
    border-radius: 3px;
    color: white;
    padding: 5px 20px;
  }

  .phrase {
    padding: 20px;
    font-size: 14px;
    text-align: center;
    font-weight: 300;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
}
