.maincontainer {
  min-height: 100vh;
  overflow-y: hidden;
  background: #5e72e4;
}

.nextcontainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0px;
}

@media screen and (min-width: 200px) {
}

@media screen and (min-width: 300px) {
}

@media screen and (min-width: 400px) {
}

@media screen and (min-width: 500px) {
}

@media screen and (min-width: 600px) {
}

@media screen and (min-width: 700px) {
}

@media screen and (min-width: 800px) {
}

@media screen and (min-width: 900px) {
}

@media screen and (min-width: 1000px) {
}

@media (min-width: 200px) and (max-width: 1000px) {
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1000px) and (max-width: 1500px) {
}

/* Media Query for Large screens */
@media (min-width: 1500px) and (max-width: 1500px) {
}

// for 125% zoom
@media (-webkit-min-device-pixel-ratio: 1.25) {
}

// for 150% zoom
@media (-webkit-min-device-pixel-ratio: 1.5) {
}
