.maincontainer {
  height: 88vh;
  width: 82vw;
  overflow-y: auto;
  background: #5e72e4;
  padding: 30px 50px;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2vw;
  background: white;
  padding: 0.5vw 0.6vw;
  border: none;
  border-radius: 50px;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
}

.holderactive {
  background: #5e72e4;
  color: white;
  font-size: 1vw;
  font-weight: 300;
  padding: 0.5vw 1vw;
  border: none;
  border-radius: 50px;
}

.holder {
  background: transparent;
  color: black;
  padding: 0.5vw 0.9vw;
  font-size: 1vw;
  font-weight: 300;
  border: none;
  border-radius: 50px;
}

.holder:hover {
  cursor: pointer;
  font-size: 1vw;
  font-weight: 300;
  transition-timing-function: linear;
  background: #5e72e4;
  color: white;
  padding: 0.5vw 1vw;
  border: none;
  border-radius: 50px;
}

.dayselect {
  background: #ffffff;
  width: 10vw;
  padding: 0.6vw;
  color: black;
  border-radius: 10px;
  font-size: 1vw;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url("https://www.svgrepo.com/show/80156/down-arrow.svg");
  background-repeat: no-repeat;
  background-size: 1vw 1vw;
  background-position: calc(100% - 16px);
}

.dayselect:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.cardsholder {
  padding: 20px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  column-gap: 20px;
}

.summarycardsholder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0px 0px 0px;
  width: 100%;
  height: fit-content;
}

// .quicksummarycards {
//   flex: 1.5;
//   background: white;
//   min-height: 39.7vh;
//   border-radius: 10px;
//   box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
// }

.carouselcard {
  flex: 1.5;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 50px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  gap: 8vh;
  height: 35vh;
}

.summarytopbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  height: 20%;
}

.datainsights {
  flex: 1;
  min-height: 100%;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
  padding: 40px;
}

.summaryheading {
  font-size: 32px;
}

.bulbicon {
  height: 60px;
  width: 60px;
}

.quicksummaryinfo {
  font-size: 24px;
  padding: 10px 0px;
  font-weight: 300;
}

.improved {
  color: #2ba65a;
  font-style: italic;
}

.boldtext {
  font-weight: 500;
}

.reduced {
  color: #ee4143;
  font-style: italic;
}

.insightstext {
  font-size: 14px;
}

.greentext {
  color: #2ba65a;
}

.redtext {
  color: #ee4143;
}

.buttoninsightsholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 10px 0px;
}

.insightsbutton {
  width: 200px;
  padding: 10px 20px;
  background: transparent;
  border: 1px solid #226bf6;
  border-radius: 3px;
  font-size: 16px;
}

@media screen and (min-width: 200px) {
}

@media screen and (min-width: 300px) {
}

@media screen and (min-width: 400px) {
}

@media screen and (min-width: 500px) {
}

@media screen and (min-width: 600px) {
}

@media screen and (min-width: 700px) {
}

@media screen and (min-width: 800px) {
}

@media screen and (min-width: 900px) {
}

@media screen and (min-width: 1000px) {
}

@media (min-width: 200px) and (max-width: 1000px) {
  .maincontainer {
    height: 88vh;
    width: 100vw;
    overflow-y: auto;
    background: #ececec;
    padding: 30px 50px;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1000px) and (max-width: 1500px) {
  // .cardsholder {
  //   padding: 20px 0px;
  //   display: grid;
  //   grid-template-columns: repeat(3, 1fr);
  //   row-gap: 20px;
  //   column-gap: 20px;
  // }

  // .leftbar {
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-start;
  //   gap: 2vw;
  //   background: white;
  //   padding: 8px 10px;
  //   border: none;
  //   border-radius: 50px;
  //   box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
  // }

  // .holderactive {
  //   background: #226bf6;
  //   color: white;
  //   font-size: 14px;
  //   font-weight: 300;
  //   padding: 8px 18px;
  //   border: none;
  //   border-radius: 50px;
  // }

  // .holder {
  //   background: transparent;
  //   color: black;
  //   padding: 8px 18px;
  //   font-size: 14px;
  //   font-weight: 300;
  //   border: none;
  //   border-radius: 50px;
  // }

  // .holder:hover {
  //   cursor: pointer;
  //   font-size: 14px;
  //   font-weight: 300;
  //   transition-timing-function: linear;
  //   background: #226bf6;
  //   color: white;
  //   padding: 8px 18px;
  //   border: none;
  //   border-radius: 50px;
  // }

  // .summarycardsholder {
  //   display: flex;
  //   align-items: flex-start;
  //   justify-content: space-between;
  //   gap: 20px;
  //   min-height: 35vh;
  //   padding: 20px 0px 0px 0px;
  //   width: 100%;
  // }

  // .quicksummaryinfo {
  //   font-size: 16px;
  //   padding: 10px 0px;
  //   font-weight: 300;
  // }

  // .summaryheading {
  //   font-size: 22px;
  // }

  // .bulbicon {
  //   height: 50px;
  //   width: 50px;
  // }

  // .analyticsicon {
  //   height: 50px;
  //   width: 50px;
  // }

  // .insightsbutton {
  //   width: 150px;
  //   padding: 10px 20px;
  //   background: transparent;
  //   border: 1px solid #226bf6;
  //   border-radius: 3px;
  //   font-size: 14px;
  // }
}

/* Media Query for Large screens */
@media (min-width: 1500px) and (max-width: 1800px) {
  // .cardsholder {
  //   padding: 20px 0px;
  //   display: grid;
  //   grid-template-columns: repeat(3, 1fr);
  //   row-gap: 20px;
  //   column-gap: 20px;
  // }

  // .leftbar {
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-start;
  //   gap: 2vw;
  //   background: white;
  //   padding: 8px 10px;
  //   border: none;
  //   border-radius: 50px;
  //   box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
  // }

  // .holderactive {
  //   background: #226bf6;
  //   color: white;
  //   font-size: 14px;
  //   font-weight: 300;
  //   padding: 8px 18px;
  //   border: none;
  //   border-radius: 50px;
  // }

  // .holder {
  //   background: transparent;
  //   color: black;
  //   padding: 8px 18px;
  //   font-size: 14px;
  //   font-weight: 300;
  //   border: none;
  //   border-radius: 50px;
  // }

  // .holder:hover {
  //   cursor: pointer;
  //   font-size: 14px;
  //   font-weight: 300;
  //   transition-timing-function: linear;
  //   background: #226bf6;
  //   color: white;
  //   padding: 8px 18px;
  //   border: none;
  //   border-radius: 50px;
  // }

  // .summarycardsholder {
  //   display: flex;
  //   align-items: flex-start;
  //   justify-content: space-between;
  //   gap: 20px;
  //   min-height: 35vh;
  //   padding: 20px 0px 0px 0px;
  //   width: 100%;
  // }

  // .quicksummaryinfo {
  //   font-size: 16px;
  //   padding: 10px 0px;
  //   font-weight: 300;
  // }

  // .summaryheading {
  //   font-size: 22px;
  // }

  // .bulbicon {
  //   height: 50px;
  //   width: 50px;
  // }

  // .analyticsicon {
  //   height: 50px;
  //   width: 50px;
  // }

  // .insightsbutton {
  //   width: 150px;
  //   padding: 10px 20px;
  //   background: transparent;
  //   border: 1px solid #226bf6;
  //   border-radius: 3px;
  //   font-size: 14px;
  // }
}

// Hadling for 125% zoom
@media (-webkit-min-device-pixel-ratio: 1.25) {
  // .cardsholder {
  //   padding: 20px 0px;
  //   display: grid;
  //   grid-template-columns: repeat(3, 1fr);
  //   row-gap: 20px;
  //   column-gap: 20px;
  // }

  // .leftbar {
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-start;
  //   gap: 2vw;
  //   background: white;
  //   padding: 8px 10px;
  //   border: none;
  //   border-radius: 50px;
  //   box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
  // }

  // .holderactive {
  //   background: #226bf6;
  //   color: white;
  //   font-size: 14px;
  //   font-weight: 300;
  //   padding: 8px 18px;
  //   border: none;
  //   border-radius: 50px;
  // }

  // .holder {
  //   background: transparent;
  //   color: black;
  //   padding: 8px 18px;
  //   font-size: 14px;
  //   font-weight: 300;
  //   border: none;
  //   border-radius: 50px;
  // }

  // .holder:hover {
  //   cursor: pointer;
  //   font-size: 14px;
  //   font-weight: 300;
  //   transition-timing-function: linear;
  //   background: #226bf6;
  //   color: white;
  //   padding: 8px 18px;
  //   border: none;
  //   border-radius: 50px;
  // }

  // .summarycardsholder {
  //   display: flex;
  //   align-items: flex-start;
  //   justify-content: space-between;
  //   gap: 20px;
  //   min-height: 35vh;
  //   padding: 20px 0px 0px 0px;
  //   width: 100%;
  // }

  // .quicksummaryinfo {
  //   font-size: 16px;
  //   padding: 10px 0px;
  //   font-weight: 300;
  // }

  // .summaryheading {
  //   font-size: 22px;
  // }

  // .bulbicon {
  //   height: 50px;
  //   width: 50px;
  // }

  // .analyticsicon {
  //   height: 50px;
  //   width: 50px;
  // }

  // .insightsbutton {
  //   width: 150px;
  //   padding: 10px 20px;
  //   background: transparent;
  //   border: 1px solid #226bf6;
  //   border-radius: 3px;
  //   font-size: 14px;
  // }
}
