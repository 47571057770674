.maintop {
  width: 100vw;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #5e72e4;
  gap: 20px;
  padding: 20px 40px;
}

.leftuserdetails {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex: 1;
}

.profileimage {
  width: 3vw;
  height: 3vw;
  border: none;
  border-radius: 50%;
}

.profileimage:hover {
  cursor: pointer;
}

.logoholder {
  flex: 1;
}

.logo {
  width: 12vw;
  height: auto;
}

.welcometext {
  color: white;
  font-size: 0.8vw;
}

.belowwelcome {
  color: white;
  font-size: 0.9vw;
}

.middlesearchbar {
  flex: 1.6;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5vh;
  border-radius: 10px;
  padding: 0px 20px;
}

.middleholder {
  flex: 1.6;
}

.searchinput {
  flex: 1;
  border: none;
  background: transparent;
  font-size: 1vw;
}

.rightinformation {
  flex: 2.2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.searchicon:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.iconinfoholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.icon {
  height: 1.2vw;
  width: 1.2vw;
}

.todaytime,
.todaydate {
  color: white;
  font-size: 1vw;
}

.selectlocation {
  background: transparent;
  border: none;
  color: white;
}

@media screen and (min-width: 200px) {
}

@media screen and (min-width: 300px) {
}

@media screen and (min-width: 400px) {
}

@media screen and (min-width: 500px) {
}

@media screen and (min-width: 600px) {
}

@media screen and (min-width: 700px) {
}

@media screen and (min-width: 800px) {
}

@media screen and (min-width: 900px) {
}

@media screen and (min-width: 1000px) {
}

@media (min-width: 200px) and (max-width: 1000px) {
  .rightinformation {
    display: none;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1000px) and (max-width: 1500px) {
  .middlesearchbar {
    flex: 1.3;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5vh;
    border-radius: 10px;
    padding: 0px 20px;
  }
}

/* Media Query for Large screens */
@media (min-width: 1500px) and (max-width: 1500px) {
}

// for 125% zoom
@media (-webkit-min-device-pixel-ratio: 1.25) {
}

// for 150% zoom
@media (-webkit-min-device-pixel-ratio: 1.5) {
}
